import { userAxios } from '../index'

const packageMembershipList = async (perPage) => {
    try {
        return await userAxios.get(`procedure-packages/membership/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const packageMembershipCreate = async (data) => {
    try {
        return await userAxios.post('procedure-packages', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const packageMembershipUpdate = async (id, data) => {
    try {
        return await userAxios.put(`procedure-packages/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const packageMembershipView = async (id) => {
    try {
        return await userAxios.get(`procedure-packages/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const packageMembershipFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`procedure-packages/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const packageMembershipPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`procedure-packages/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const packageMembershipFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`procedure-packages/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    packageMembershipList,
    packageMembershipCreate,
    packageMembershipUpdate,
    packageMembershipView,
    packageMembershipFilter,
    packageMembershipPagination,
    packageMembershipFilterPagination,
}