var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.$can(
        'filter',
        'module_system_settings.settings.admin_treatments.treatments'
      )
    )?_c('PackageFilter',{attrs:{"loading":_vm.loading},on:{"searchFilter":_vm.searchFilter,"filterPage":_vm.filterPage}}):_vm._e(),_c('b-card',[_c('TitleTable',{attrs:{"titleTable":"Packages Membership","iconTable":"PackageIcon"}}),_c('div',{staticClass:"m-1"},[(_vm.isEditPackageSidebarActive)?_c('PackageEdit',{attrs:{"is-edit-package-sidebar-active":_vm.isEditPackageSidebarActive,"itemEdit":_vm.itemEdit},on:{"update:isEditPackageSidebarActive":function($event){_vm.isEditPackageSidebarActive=$event},"update:is-edit-package-sidebar-active":function($event){_vm.isEditPackageSidebarActive=$event},"editPackageSuccess":_vm.editPackageSuccess}}):_vm._e(),_c('PackageAdd',{attrs:{"is-add-new-package-sidebar-active":_vm.isAddNewPackageSidebarActive},on:{"update:isAddNewPackageSidebarActive":function($event){_vm.isAddNewPackageSidebarActive=$event},"update:is-add-new-package-sidebar-active":function($event){_vm.isAddNewPackageSidebarActive=$event},"createPackage":_vm.createPackage}}),(_vm.conditionviewPackage)?_c('PackageView',{attrs:{"PackageById":_vm.viewPackageId},on:{"closeModalPackage":_vm.closeModalPackage}}):_vm._e(),_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t("Show")))]),_c('v-select',{staticClass:"per-page-selector d-inline-block mx-50",attrs:{"options":_vm.perPageOptions,"clearable":false},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}}),_c('label',[_vm._v(_vm._s(_vm.$t("Entries")))])],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('GoBack',{staticClass:"mr-2"}),(
                _vm.$can(
                  'store',
                  'module_system_settings.settings.admin_treatments.treatments'
                )
              )?_c('b-button',{attrs:{"disabled":_vm.loading,"variant":"primary"},on:{"click":function($event){_vm.isAddNewPackageSidebarActive = true}}},[(!_vm.loading)?_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.$t("Add"))+" Package")]):_c('span',[_c('SpinnerLoading')],1)]):_vm._e()],1)])],1)],1),_c('b-table',{staticClass:"position-relative",attrs:{"items":_vm.items,"fields":_vm.fields,"hover":"","bordered":"","responsive":"","primary-key":"id","show-empty":"","empty-text":"No matching records found"},scopedSlots:_vm._u([{key:"cell(status)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_c('span',{staticClass:"align-text-top text-capitalize"},[_vm._v(_vm._s(data.item.status ? _vm.$t("Active") : _vm.$t("Inactive")))])])]}},{key:"cell(actions)",fn:function(data){return [(
            _vm.$can(
              'update',
              'module_system_settings.settings.admin_treatments.treatments'
            )
          )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(40, 199, 111, 0.15)'),expression:"'rgba(40, 199, 111, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Update'),expression:"'Update'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"variant":"flat-primary"},on:{"click":function($event){return _vm.editTreatment(data.item)}}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}})],1):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(40, 199, 111, 0.15)'),expression:"'rgba(40, 199, 111, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('View Items'),expression:"'View Items'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"variant":"flat-warning"},on:{"click":function($event){return _vm.viewPackage(data.item.id)}}},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}})],1)]}}])}),_c('PaginationTable',{attrs:{"dataMeta":_vm.dataMeta,"totalUsers":_vm.totalUsers,"perPage":_vm.perPage,"currentPage":_vm.currentPage},on:{"page-changed":_vm.pageChanged}})],1),_c('ToastNotification',{ref:"toast"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }