<template>
  <b-sidebar
    id="add-new-package-sidebar"
    :visible="isEditPackageSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-edit-package-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Edit Package</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group :label="$t('Form.Name')" label-for="name">
              <b-form-input
                id="name"
                v-model="stateData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Package name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Description -->
          <validation-provider
            #default="validationContext"
            name="description"
            rules="required"
          >
            <b-form-group
              :label="$t('Form.Description')"
              label-for="description"
            >
              <b-form-input
                id="description"
                v-model="stateData.description"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Package description"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Price -->
          <validation-provider
            #default="validationContext"
            name="Price"
            rules="required"
          >
            <b-form-group label="Price" label-for="price">
              <b-form-input
                id="price"
                v-model="stateData.price"
                autofocus
                :state="getValidationState(validationContext)"
                type="number"
                placeholder="Package price"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- consult price -->
          <validation-provider
            #default="validationContext"
            name="consult_price_id"
            rules=""
          >
            <b-form-group label="Consul price" label-for="consult_price_id">
              <v-select
                id="consult_price_id"
                v-model="stateData.consult_price_id"
                :state="getValidationState(validationContext)"
                :options="consultPriceOptions"
                :reduce="(value) => value.id"
                label="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- membership -->
          <validation-provider
            #default="validationContext"
            name="membership_id"
            rules=""
          >
            <b-form-group label="Membership" label-for="membership_id">
              <v-select
                id="membership_id"
                v-model="stateData.membership_id"
                :state="getValidationState(validationContext)"
                :options="membershipOptions"
                :reduce="(value) => value.id"
                label="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- laboratories -->
          <validation-provider
            #default="validationContext"
            name="Laboratories"
            rules=""
          >
            <b-form-group label="Laboratories" label-for="laboratories">
              <v-select
                id="laboratories"
                v-model="stateData.laboratories"
                :state="getValidationState(validationContext)"
                :options="laboratoriesOptions"
                multiple
                :reduce="(val) => val.id"
                label="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- laboratories packs -->
          <validation-provider
            #default="validationContext"
            name="Laboratory Packs"
            rules=""
          >
            <b-form-group label="Laboratory Packs" label-for="laboratories">
              <v-select
                id="laboratories"
                v-model="stateData.laboratories_pack"
                :state="getValidationState(validationContext)"
                :options="laboratoriesPackOptions"
                :reduce="(val) => val.id"
                multiple
                label="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- tests -->
          <validation-provider
            #default="validationContext"
            name="Tests"
            rules=""
          >
            <b-form-group label="Tests" label-for="test">
              <v-select
                id="test"
                v-model="stateData.tests"
                :state="getValidationState(validationContext)"
                :options="testOptions"
                multiple
                :reduce="(val) => val.id"
                label="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- procedures -->
          <validation-provider
            #default="validationContext"
            name="Procedures"
            rules=""
          >
            <b-form-group label="Procedures" label-for="procedure">
              <v-select
                id="procedure"
                v-model="stateData.procedures"
                :state="getValidationState(validationContext)"
                :options="procedureOptions"
                multiple
                :reduce="(val) => val.id"
                label="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- diagnostic imagings -->
          <validation-provider
            #default="validationContext"
            name="Diagnostic Imagings"
            rules=""
          >
            <b-form-group label="Diagnostic Imagings" label-for="diagnostic">
              <v-select
                id="diagnostic"
                v-model="stateData.diagnostic_imagings"
                :state="getValidationState(validationContext)"
                :options="diagnosticImagingOptions"
                multiple
                :reduce="(val) => val.id"
                label="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Treatment -->
          <validation-provider
            #default="validationContext"
            name="Treatments"
            rules=""
          >
            <b-form-group label="Treatments" label-for="treatment">
              <v-select
                id="treatment"
                v-model="treatmentsSelected"
                :state="getValidationState(validationContext)"
                :options="
                  treatmentsOptions.filter(
                    (o) => treatmentsSelected.indexOf(o) < 0
                  )
                "
                multiple
                label="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div
            v-for="(item, index) in stateData.treatments"
            :key="index"
            class="d-flex"
          >
            <input
              type="text"
              class="form-control"
              :value="item.name"
              disabled
            />
            <input
              type="number"
              class="form-control"
              v-model="item.cant"
              max="10"
              min="1"
            />
          </div>

          <!-- status -->
          <validation-provider
            #default="validationContext"
            name="Status"
            rules="required"
          >
            <b-form-group :label="$t('Form.Status')" label-for="status">
              <v-select
                id="status"
                v-model="stateData.status"
                :state="getValidationState(validationContext)"
                :options="optionsStatus"
                :reduce="(val) => val.value"
                :clearable="false"
                label="text"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="loading"
            >
              <span v-if="!loading">Edit</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-danger"
              @click="hide"
            >
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SpinnerLoading from "@/components/SpinnerLoading";

import vSelect from "vue-select";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import { onMounted, ref, watch } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";

import axiosPC from "@/services/admin/ProcedurePackages.js";
import axiosL from "@/services/admin/consults/laboratories";
import axiosT from "@/services/admin/consults/tests";
import axiosP from "@/services/admin/consults/procedures";
import axiosDI from "@/services/admin/consults/diagnosticImages";
import axiosTR from "@/services/admin/consults/treatments";
import axiosCP from "@/services/admin/consults/consultPrices";
import axiosM from "@/services/admin/membership";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    SpinnerLoading,

    vSelect,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditPackageSidebarActive",
    event: "update:is-edit-package-sidebar-active",
  },
  props: {
    isEditPackageSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      required,
      config: {
        dateFormat: "m-d-Y",
        allowInput: true,
      },
    };
  },
  setup(props, { emit }) {
    const optionsStatus = [
      { text: "Active", value: true },
      { text: "Inactive", value: false },
    ];

    const treatmentsSelected = ref([]);
    watch(treatmentsSelected, (newValue, oldValue) => {
      if(oldValue.length == 0 && stateData.value.treatments.length > 0) return

      if (newValue.length > oldValue.length) {
        const selected = newValue[newValue.length - 1];
        stateData.value.treatments.push({
          id: selected.id,
          name: selected.name,
          price: selected.price,
          cant: 1,
        });
      } else {
        const treatmentEliminated = oldValue.findIndex(
          (elemento) => newValue.indexOf(elemento) == -1
        );
        stateData.value.treatments.splice(treatmentEliminated, 1);
      }
    });

    onMounted(() => {
      getDataItems();
      getData();
    });

    const stateData = ref({});

    const getData = () => {
      const { id } = props.itemEdit.value;
      axiosPC.packageView(id).then(({ registro }) => {
        const treatmentsDefault = registro.treatments.map((res) => {
          return {
            id: res.id,
            name: res.name,
            price: res.price,
            cant: res.pivot.cant
          }
        });
        stateData.value.status = registro.status;
        stateData.value.name = registro.name;
        stateData.value.description = registro.description;
        stateData.value.price = registro.price;
        stateData.value.consult_price_id = registro.consult_price_id;
        stateData.value.membership_id = registro.membership_id;
        stateData.value.laboratories = extractIds(registro.laboratories),
        stateData.value.laboratories_pack = extractIds(registro.laboratories_pack),
        stateData.value.tests = extractIds(registro.tests),
        stateData.value.procedures = extractIds(registro.procedures),
        stateData.value.diagnostic_imagings = extractIds(registro.diagnostic_imagings),
        stateData.value.treatments = treatmentsDefault;
        treatmentsSelected.value = treatmentsDefault.map(treatment => {
          return {
            id: treatment.id, 
            name: treatment.name, 
            price: treatment.price
          }
        });
      });
    };

    const extractIds = (list) => list.map(item => item.id);

    const laboratoriesOptions = ref([]);
    const laboratoriesPackOptions = ref([]);
    const testOptions = ref([]);
    const procedureOptions = ref([]);
    const diagnosticImagingOptions = ref([]);
    const treatmentsOptions = ref([]);
    const consultPriceOptions = ref([]);
    const membershipOptions = ref([]);

    const getDataItems = () => {
      axiosL.laboratoryList(200).then(({ data }) => {
        laboratoriesOptions.value = data;
        data.forEach((item) => {
          if (item.categories[0].price) {
            if (
              !laboratoriesPackOptions.value.some(
                (itemFind) => itemFind.id === item.categories[0].id
              )
            ) {
              laboratoriesPackOptions.value.push(item.categories[0]);
            }
          }
        });
      });
      axiosT.testList(20).then(({ data }) => {
        testOptions.value = data;
      });
      axiosP.procedureList(20).then(({ data }) => {
        procedureOptions.value = data;
      });
      axiosDI.diagnosticImageList(20).then(({ data }) => {
        diagnosticImagingOptions.value = data;
      });
      axiosTR.treatmentList(20).then(({ data }) => {
        treatmentsOptions.value = data;
      });
      axiosCP.consultPriceList(10).then(({data}) => {
        const consult = data.map((item) => {
          return {
            ...item,
            name: `${item.name} - ${item.price}`
          }
        });
        consultPriceOptions.value = consult;
      });
      axiosM.membershipList(10).then(({data}) => {
        membershipOptions.value = data;
      });
    };

    const resetuserData = () => {
      stateData.value = JSON.parse(JSON.stringify(props.itemEdit.value));
    };

    const loading = ref(false);
    const onSubmit = () => {
      const { id } = props.itemEdit.value;
      loading.value = true;
      const PackageItem = JSON.parse(JSON.stringify(stateData));
      const data = {
        ...PackageItem.value,
      };
      axiosPC
        .packageUpdate(id, data)
        .then(() => {
          loading.value = false;
          resetuserData();
          emit("editPackageSuccess", true);
        })
        .catch(() => {
          emit("editPackageSuccess", false);
          loading.value = false;
        });
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);
    return {
      stateData,
      loading,
      treatmentsSelected,
      optionsStatus,
      treatmentsOptions,
      laboratoriesOptions,
      laboratoriesPackOptions,
      consultPriceOptions,
      membershipOptions,
      testOptions,
      procedureOptions,
      diagnosticImagingOptions,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      extractIds,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
